import React, { useEffect } from 'react';
import { Datagrid, ListContextProvider, TextField, Form, TextInput, DateField, useGetList, useListContext, useListController, useRedirect, } from 'react-admin';
import { Paper, Typography, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import OperationChildrenList from './OperationChildrenList';

const useDatagridStyles = makeStyles({
    headerCell: {
        backgroundColor: '#f0f0f0',
    },
    row: {
        backgroundColor: '#f0f0f0',
    },
});

const ReportInfo = ({ data, total }) => {

    console.log({ data, total })

    return <Paper style={{ width: "100%", background: "#f0f0f0" }}>
        <Typography fontSize={20} margin={'10px'}>Операция задачи {!data || total == 0
            ? "не найдена" : ""}</Typography>
        {data && total > 0 &&
            <CustomTaskOperationsList data={data} total={total} />
        }
    </Paper>
}

const CustomTaskOperationsList = ({ data, total }) => {

    const listProps = {
        data,
        total,
        sort: {},
        //resource: "findOperationToken"
    };

    const classes = useDatagridStyles();

    return (
        <ListContextProvider value={listProps}>
            <Datagrid classes={classes} isRowSelectable={() => {
                return false;
            }}
            >
                <TextField source="id" />
                <TextField source="operationNumber" label="Токен" />
                <TextField source="operationType.name" label="Тип операции" />
                <TextField source="operationState.name" label="Результат" />
                <TextField source="relyingParty.name" label="Контрагент" />
                <TextField source="serviceSupplier.name" label=" Поставщик" />
                <DateField source="createMoment" label="Момент старта" showTime />
                <DateField source="modifyMoment" label="Момент завершения" showTime />
            </Datagrid>

        </ListContextProvider>
    )
}

const ReportTokenFilter = props => {
    const {
        filterValues,
        setFilters,
        refetch
    } = useListContext();

    useEffect(() => {
        refetch();
    }, [filterValues])

    const onSubmit = values => {
        if (Object.keys(values).length > 0) {
            setFilters(values);
        }
    };

    return (
        props.context !== "button" && (
            <Box>
                <Form onSubmit={onSubmit}>
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end' }}>
                        <TextInput
                            alwaysOn
                            style={{ flexGrow: 1, paddingRight: '10px' }}
                            variant="outlined"
                            resettable
                            helperText={false}
                            source="reportNumber"
                            label="Токен отчета"
                            InputProps={{ style: { fontSize: "small" } }}
                        />

                        <Button variant="contained" color="primary" type="submit" style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                            ПОИСК
                        </Button>
                    </Box>
                </Form>
            </Box>
        )
    );
};

const ReportTokenList = (props) => {


    const listProps = useListController(
        {
            sort: {
                field: 'id',
                order: 'DESC'
            },
            queryOptions: {
                enabled: false
            },
            perPage: 10,
            ...props,
            resource: "findReport"
        })


    return (
        <ListContextProvider value={listProps}>
            <div style={{ marginTop: '20px' }}>
                <ReportTokenFilter />
                <Paper style={{ marginTop: '20px' }}>
                    <Datagrid
                        style={{ margin: 'auto', marginTop: '20px', width: '95%' }}
                        expand={({ id, record, resource }) => {

                            return <ReportAdditionalInfo record={record} />
                        }}
                    >
                        <TextField source="id" />
                        <TextField source="lastName" label="Фамилия" />
                        <TextField source="firstName" label="Имя" />
                        <TextField source="midName" label="Отчество" />
                        <TextField source="taskState" label="Статус" />
                        <DateField source="createMoment" label="Момент старта" showTime />
                        <DateField source="modifyMoment" label="Момент завершения" showTime />
                    </Datagrid>
                </Paper>
            </div>
        </ListContextProvider>
    )
}

const ReportAdditionalInfo = ({ record }) => {

    console.log(record);

    const { data, total } = useGetList("findOperationToken", {
            filter: {
                operationNumber: record ? record.operationToken : ""
            }
        },
        {
            enabled: !!record,
            refetchOnMount: false,
            refetchOnWindowFocus: false
        })

    return data && total > 0
        ? <>
            <ReportInfo data={data} total={total} />
            <OperationChildrenList id={`${data[0].id}`} />
        </>
        : null
}

export default ReportTokenList;