import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactDOM from "react-dom";
import {init as initApm} from '@elastic/apm-rum';
import {BrowserRouter} from 'react-router-dom';

// console.log('here');

// ReactDOM.render(<App />, document.getElementById('root'));

// const apm = initApm({
//     active: process.env.REACT_APP_ELASTIC_APM_ACTIVE,
//     serviceName: process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME,
//     serviceVersion: process.env.REACT_APP_ELASTIC_APM_SERVICE_VERSION,
//     serverUrl: process.env.REACT_APP_ELASTIC_APM_SERVER_URL,
//     environment: process.env.REACT_APP_ELASTIC_APM_ENVIRONMENT,
//     logLevel: process.env.REACT_APP_ELASTIC_APM_LOG_LEVEL,
//     distributedTracing: process.env.REACT_APP_ELASTIC_APM_DISTRIBUTED_TRACING,
//     distributedTracingOrigins: process.env.REACT_APP_ELASTIC_APM_DISTRIBUTED_TRACING_ORIGINS.split(","),
// });


const container = document.getElementById('root');
//const root = createRoot(container); // createRoot(container!) if you use TypeScript
ReactDOM.render(<BrowserRouter><App tab="home" /></BrowserRouter>, container);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
