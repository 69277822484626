import React, { useState, useEffect } from "react";
import { Datagrid, DateField, Form, List, ListContextProvider, TextField, TextInput, useListContext, useGetList, useListController } from 'react-admin';
import { Box, Button, Paper } from '@mui/material';
import OperationAttributeList from './OperationAttributeList'
import { useParams } from 'react-router-dom';
import OperationChildrenList from "./OperationChildrenList";
import OperationLogList from "./OperationLogList";

const OperationTokenFilter = props => {
    const {
        showFilter,
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter,
        refetch
    } = useListContext();

    useEffect(() => {
        if (props.operationNumber) {
            setFilters({ operationNumber: props.operationNumber })
        }
    }, [])

    useEffect(() => {
         refetch();
    }, [filterValues])

    const onSubmit = values => {
        if (Object.keys(values).length > 0) {
            setFilters(values);
        }
    };

    return (
        props.context !== "button" && (
            <Box>
                <Form onSubmit={onSubmit}>
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end' }}>
                        <TextInput
                            alwaysOn
                            style={{ flexGrow: 1, paddingRight: '10px' }}
                            variant="outlined"
                            resettable
                            helperText={false}
                            source="operationNumber"
                            label="Токен операции"
                            defaultValue={props.operationNumber ?? ""}
                            InputProps={{ style: { fontSize: "small" } }}
                        />

                        <Button variant="contained" color="primary" type="submit" style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                            ПОИСК
                        </Button>
                    </Box>
                </Form>
            </Box>
        )
    );
};

const OperationTokenList = (props) => {

    const { operationNumber } = useParams();

    const listProps = useListController(
        {
            sort: {
                field: 'id',
                order: 'DESC'
            },
            queryOptions: {
                enabled: false
            },
            perPage: 10,
            ...props,
            resource: "findOperationToken"
        })


    return (
        <ListContextProvider value={listProps}>
            <div style={{ marginTop: '20px' }}>
                <OperationTokenFilter operationNumber={operationNumber} />
                <Paper style={{ marginTop: '20px' }}>
                    <Datagrid
                        style={{ margin: 'auto', marginTop: '20px', width: '95%' }}
                        expand={({ id, rec, res }) =>
                            <>
                                <OperationAttributeList id={id} />
                                <OperationLogList id={id} />
                                <OperationChildrenList id={id} />
                            </>
                        }
                    >
                        <TextField source="id" />
                        <TextField source="operationNumber" label="Токен" />
                        <TextField source="operationType.name" label="Тип операции" />
                        <TextField source="operationState.name" label="Результат" />
                        <TextField source="relyingParty.name" label="Контрагент" />
                        <TextField source="serviceSupplier.name" label=" Поставщик" />
                        <DateField source="createMoment" label="Момент старта" showTime />
                        <DateField source="modifyMoment" label="Момент завершения" showTime />
                    </Datagrid>
                </Paper>
            </div>
        </ListContextProvider>
    )
}

export default OperationTokenList;