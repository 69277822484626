import { Rotate90DegreesCw } from "@mui/icons-material";
import { Stack } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { useState } from 'react';
import {
    Button,
    Confirm,
    DateField,
    Edit,
    EditController,
    FunctionField,
    Labeled,
    ListButton,
    SaveButton,
    SelectField,
    SelectInput,
    TabbedForm,
    TextField,
    TextInput,
    Toolbar,
    useDataProvider,
    useNotify, usePermissions,
    useRecordContext, useRefresh
} from 'react-admin';
import { useLocation, useParams } from "react-router-dom";
import { default as roles, default as Roles } from "../../constants/roles";
import OperationStatByTypeList from "../OperationStatByTypeList";
import { PartyStatMonthlyList } from "../PartyStatMonthlyList";
import TariffList from "../TariffList";
import PartyPayments from './party-payments';
import PartyInvoiceList from "./PartyInvoiceList";
import PartyPermissionLimitList from "./PartyPermissionLimitList";
import PartyResetPasswordButton from "./PartyResetPasswordButton";
import PartyServiceList from "./PartyServiceList";

const styles = theme => ({
    user_name: {width: 450},

    // inlineBlock: {display: 'inline-flex', marginRight: '1rem', fontSize: 16},
});

const ActivateAccountButton = props => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const refresh = useRefresh();
    const notify = useNotify();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleClick = () => {
        setOpen(true);
        setLoading(false);
    };
    const handleDialogClose = () => {
        setOpen(false);
    }

    const handleConfirm = event => {
        setLoading(true);
        dataProvider.update('melko/party/activate', {id: record.id})
            .then(response => {
                setOpen(false);
                refresh();
            })
            .catch((e) => {
                setLoading(false);
                console.error(e);
                notify(`Ошибка активации учетной записи: ${e}`, {type: 'error'});
            });

    }

    return (
        <>
            <Button color="primary" label="Активировать" onClick={handleClick}>
                <Rotate90DegreesCw/>
            </Button>
            <Confirm isOpen={open} loading={loading}
                     title={`Активация учетной записи`}
                     content={`Активировать учетную запись ${record && record.name}`}
                     onConfirm={handleConfirm}
                     onClose={handleDialogClose}
            />
        </>
    )
}

const PartyEditToolbar = props => {
    const location = useLocation();
    const backUrl = location.pathname.includes('/melko/') ?
        '/melko/party?sort=id&order=DESC' :
        '/party?sort=id&order=DESC';

    const record = useRecordContext();
    const {permissions} = usePermissions();

    return (
        <Toolbar {...props}  >
            {location.pathname.match(/(party\/\d+$|party\/\d+\/1$)/g) &&
                <SaveButton/>
            }
            <ListButton label="Назад в список" icon={null} resource={backUrl}/>

            <PartyResetPasswordButton buttonStyle={{marginLeft: "auto"}}/>

            {permissions.includes(roles.melkocheck_admin) && record && record.billingEnabled && (!record.emailConfirmed || !record.isConfirmed) &&
                <ActivateAccountButton/>
            }

        </Toolbar>
    )
};

const required = value => value != null ? undefined : 'Required';

const PartyEdit = (props) => {
    const {classes} = props;
    const location = useLocation();
    const {id} = useParams();
    const {permissions} = usePermissions();

    return (

        <Edit undoable={false} title={"Контрагент #" + id}>
            <EditController {...props} >
                {controllerProps => {
                    console.log("controllerProps", controllerProps);
                    return (
                        <TabbedForm {...controllerProps}
                                    toolbar={<PartyEditToolbar location={location}/>}
                                    submitOnEnter={false}>

                            <TabbedForm.Tab label="Учетная запись">
                                {/*<Typography variant="h6" gutterBottom>
                                    Identity
                                </Typography>
*/}
                                <Stack direction="row" className={classes.user_name} >
                                    <Labeled label="ID" sx={{width: "400px"}}>
                                        <TextField source="id" variant='' />
                                    </Labeled>
                                    {controllerProps.record.billingEnabled &&
                                        <Labeled label="Баланс лицевого счета"
                                                 sx={{fontWeight: 'bold', width:'200px'}}>
                                            <FunctionField render={record => {
                                                return (record && record.accountInfo) ?
                                                    (record.accountInfo.accountBalance / 100.0).toLocaleString('ru-RU') + " руб." : ""
                                            }} sx={{fontSize: 'large'}} />
                                        </Labeled>
                                    }

                                </Stack>

                                <Labeled label="Логин" fullWidth sx={{marginTop:"20px"}}>
                                    <TextField source="username" variant='' gutterBottom/>
                                </Labeled>


                                <Labeled label="ИНН" sx={{marginTop: "20px"}}>
                                    <TextInput source="inn" label="" className={classes.user_name}/>
                                </Labeled>


                                {/*{!controllerProps.record.billingEnabled &&*/}
                                {/*
                                                <>
                                                    <Labeled label="API accessKey" fullWidth>
                                                        <TextField source="apiAccessKey" variant="subtitle1" gutterBottom/>
                                                    </Labeled>

                                                    <Labeled label="API secretKey" fullWidth>
                                                        <FunctionField render={record => {
                                                            return ("******")
                                                        }} variant='subtitle1' gutterBottom/>
                                                    </Labeled>
                                                </>
*/}
                                {/*}*/}

                                <Labeled label="Название">
                                    <TextInput source="name" label="" className={classes.user_name}/>
                                </Labeled>
                                <Labeled label="Контактное лицо" fullWidth>
                                    <TextInput source="personName" label="" className={classes.user_name}/>
                                </Labeled>
                                <Labeled label="Телефон" fullWidth>
                                    <TextInput source="phone" label="" className={classes.user_name}/>
                                </Labeled>

                                {!controllerProps.record.billingEnabled &&
                                    <Labeled label="Email" fullWidth>
                                        <TextInput source="email" label="" className={classes.user_name}/>
                                    </Labeled>
                                }

                                {controllerProps.record.billingEnabled &&
                                    <Stack direction="row" gap={1} justifyContent="space-between"
                                           className={classes.user_name}>
                                        <Labeled label="Подтверждение email">
                                            <SelectInput source="emailConfirmedBool" label=""
                                                         validate={required}
                                                         disabled={permissions.includes(roles.party_admin) ? false : true}
                                                         choices={[
                                                             {id: false, name: 'Не подтвержден'},
                                                             {id: true, name: 'Подтвержден'}
                                                         ]}
                                            />
                                        </Labeled>
                                        <Labeled label="Подтверждение ИНН+домен">
                                            <SelectInput source="isConfirmed" label=""
                                                         disabled={true}
                                                         choices={[
                                                             {id: false, name: 'Не подтверждено'},
                                                             {id: true, name: 'Подтверждено'}
                                                         ]}/>
                                        </Labeled>
                                    </Stack>
                                }
                                <Stack direction="row" gap={1} className={classes.user_name}
                                       justifyContent="space-between"
                                >
                                    <Labeled label="Активен">
                                        <SelectInput source="active" label="" validate={required}
                                                     disabled={permissions.includes(roles.party_admin) ? false : true}
                                                     choices={[
                                                         {id: 0, name: 'Нет'},
                                                         {id: 1, name: 'Да'}
                                                     ]}/>
                                    </Labeled>
                                    <Labeled label="Внутренний ЛС IDX">
                                        <SelectInput source="isTest" label=""
                                                     disabled={permissions.includes(roles.party_admin, roles.melkocheck_admin) ? false : true}
                                                     choices={[
                                                         {id: false, name: 'Нет'},
                                                         {id: true, name: 'Да'}
                                                     ]}/>
                                    </Labeled>
                                </Stack>

                                <Stack direction="row" className={classes.user_name}>
                                    <Labeled label="Тип" sx={{width: "400px"}} >
                                        <SelectField variant="subtitle1" source="partyType" choices={[
                                            {id: "serviceProvider", name: "Контрагент"},
                                            {id: "idp", name: "Поставщик"}
                                        ]} gutterBottom/>
                                    </Labeled>

                                    <Labeled label="Мелкочек" sx={{width: "200px"}}>
                                        <SelectField variant="subtitle1" source="billingEnabled" choices={[
                                            {id: true, name: "Да"},
                                            {id: false, name: "Нет"}
                                        ]} gutterBottom/>
                                    </Labeled>
                                </Stack>

                                <Labeled label="Дата регистрации" sx={{marginTop: "20px"}}>
                                    <DateField source="createMoment" variant="subtitle1"/>
                                </Labeled>

                                {/*
                                    Для обычных контрагентов показываем как логин
                                    {!controllerProps.record.billingEnabled &&
                                        <Stack direction="row" gap={2}>
                                            <Labeled label="Логин" gutterBottom>
                                                <TextField source="username" variant='' gutterBottom/>
                                            </Labeled>

                                        </Stack>
                                    }

                                    Для мелкочеков - Email/Логин
                                    {controllerProps.record.billingEnabled &&
                                        <Labeled label="Логин">
                                            <TextField source="username" variant='' gutterBottom/>
                                        </Labeled>
                                    }
*/}

                                {/*
                                    {!controllerProps.record.billingEnabled &&
                                        <>
                                            <Labeled label="API accessKey">
                                                <TextField source="apiAccessKey" variant="subtitle1"/>
                                            </Labeled>
                                            <Labeled label="API secretKey">
                                                <FunctionField render={record => {
                                                    return ("******")
                                                }} variant='subtitle1' gutterBottom/>
                                            </Labeled>
                                        </>
                                    }
*/}


                            </TabbedForm.Tab>


                            {
                                /*Для мелкочека*/

                                controllerProps.isLoading === false &&
                                controllerProps.record.partyType === 'serviceProvider' &&
                                controllerProps.record.billingEnabled &&
                                [
                                    <TabbedForm.Tab label="Аттрибуты юр.лица" variant="outlined" margin="dense" key="1">

                                        <TextInput source="partyAttr.inn" label="ИНН"
                                                   className={classes.user_name} disabled/>
                                        <br/>
                                        <TextInput source="partyAttr.kpp" label="КПП"
                                                   className={classes.user_name}/>
                                        <TextInput source="partyAttr.nameShort" label="Краткое наименование"
                                                   fullWidth/>
                                        <TextInput source="partyAttr.nameFull" label="Полное наименование"
                                                   fullWidth/>
                                        <TextInput source="partyAttr.addressFull" label="Адрес" fullWidth/>

                                    </TabbedForm.Tab>
                                    ,
                                    <TabbedForm.Tab label="Подписки" key="2">
                                        <PartyServiceList partyId={id} record={controllerProps.record}/>
                                    </TabbedForm.Tab>
                                    ,
                                    <TabbedForm.Tab label="Платежи" key="3">
                                        <PartyPayments partyId={id}/>
                                    </TabbedForm.Tab>
                                    ,
                                    <TabbedForm.Tab label="Списания" key="4">
                                        <PartyInvoiceList partyId={id}/>
                                    </TabbedForm.Tab>
                                    ,
                                    <TabbedForm.Tab label="Статистика" key="5">
                                        <OperationStatByTypeList partyId={id}/>
                                    </TabbedForm.Tab>

                                ]
                            }
                            {/*Для обычных контрагентов*/}
                            {
                                controllerProps.isLoading === false &&
                                controllerProps.record.partyType === 'serviceProvider' &&
                                controllerProps.record.billingEnabled === false &&

                                <TabbedForm.Tab label="Права доступа и лимиты" key=".4">
                                    <PartyPermissionLimitList partyId={id} record={controllerProps.record}/>
                                </TabbedForm.Tab>

                            }
                            {
                                controllerProps.isLoading === false &&
                                controllerProps.record.partyType === 'serviceProvider' &&
                                controllerProps.record.billingEnabled === false &&
                                permissions.includes(Roles.party_admin) &&
                                [
                                    <TabbedForm.Tab label="Тарифы" key="5">
                                        <TariffList partyId={id} record={controllerProps.record}/>
                                    </TabbedForm.Tab>
                                    ,
                                    <TabbedForm.Tab label="Отчеты" key="6">
                                        <PartyStatMonthlyList partyId={id} showFilter={true}/>
                                    </TabbedForm.Tab>
                                ]
                            }
                            {
                                controllerProps.isLoading === false &&
                                controllerProps.record.partyType === 'serviceProvider' &&
                                controllerProps.record.billingEnabled === false &&

                                <TabbedForm.Tab label="Статистика" key="7">
                                    <OperationStatByTypeList partyId={id}/>
                                </TabbedForm.Tab>

                            }

                        </TabbedForm>
                    )
                }
                }
            </EditController>
        </Edit>
    )
};

export default withStyles(styles)(PartyEdit);