import {fetchUtils} from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import {getBasePath} from 'lib/utils'
import {default as queryString} from "query-string";

export const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const token = localStorage.getItem('token');
    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
    }

    console.log({url})

    return fetchUtils.fetchJson(url, options);
}

// const restProvider = simpleRestProvider(getBasePath(), httpClient);

const myProvider = {
    ...simpleRestProvider(getBasePath(), httpClient),

    customFetch: (resource, params) => {
        console.log("resource", resource, "params", params );
        
        var query = {
            filter: JSON.stringify(params.filter)
        };
        const url = getBasePath() + "/" + resource + "?" + queryString.stringify(query);
        return httpClient(url)
            .then((response) => {
                var headers = response.headers, data = response.json;
                return {headers, data};
            })
    },

    customUpdate: (resource, params) => {
        console.log("resource", resource, "params", params);
        
        var url = getBasePath() + "/" + resource + (params.id ? "/" + +params.id : "");
        return httpClient(url, {
            method: (params.method ? params.method : 'PUT'),
            body: JSON.stringify(params.data),
        }).then(function (_a) {
            var json = _a.json;
            return ({data: json});
        });

    }

}

export default myProvider; //restProvider;
/*
export default (type, resource, params) =>
  new Promise(resolve =>
    setTimeout(() => resolve(restProvider(type, resource, params)), 100)
  );
*/

/*
export default (type, resource, params) =>
  new Promise(resolve => {
    const resp = resolve(restProvider(type, resource, params));
    if (resource === 'partyPermission') {
      console.log('permission');
    }
    return resp;
  });*/
