import React, {useState} from 'react';
import Paper from '@mui/material/Paper';
import withStyles from '@mui/styles/withStyles';
import {
    Button,
    Datagrid,
    Form,
    FunctionField,
    List,
    NumberInput,
    required,
    SaveButton,
    TextField,
    useNotify,
    usePermissions, useRecordContext,
} from "react-admin";
import {useQueryClient} from 'react-query';

import AddCircle from "@mui/icons-material/AddCircle";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconCancel from "@mui/icons-material/Cancel";
import dataProvider from "../../components/data-provider";
import roles from "../../constants/roles";
import {PartyInvoiceListSimple} from "./PartyInvoiceList";
import Typography from "@mui/material/Typography";
import {Box} from "@mui/material";
import {formatDate, formatDatetime} from "../../lib/utils";
import {Replay} from "@mui/icons-material";

const styles = theme => ({
    container: {minWidth: '35em', marginLeft: '0.5em', marginTop: '1em'},
    rightAlignedCell: {textAlign: 'right'},
    leftAlignedCell: {textAlign: 'left'},
    boldCell: {fontWeight: 'bold'},
    head: {
        backgroundColor: "#ededed",
        color: theme.palette.common.white,
    },
    button: {
        marginTop: 7,
        marginLeft: 14,
        marginBottom: 10
    }
});

const IncreaseBalanceDialog = (props) => {
    const notify = useNotify();
    const {getServiceId, getServiceStateid, show, close} = props;
    const queryClient = useQueryClient();

    const handleDialogSubmit = (dialogProps) => {
        const {addCnt} = dialogProps;
        const postData = {
            addCnt: addCnt,
        };

        dataProvider.update('service/addCnt', {id: getServiceId(), data: postData})
            .then(response => {
                queryClient.invalidateQueries({queryKey: ['service']});
                props.close();
            })
            .catch((e) => {
                console.error(e);
                notify(`Ошибка добавления тестовых проверок в Стартовый тариф: ${e}`, {type: 'error'});
            });
    }

    return (
        <Dialog fullWidth open={show} onClose={close}>
            <DialogTitle>{getServiceStateid()==100 && <>Добавить проверок</>}
                {getServiceStateid()==900 && <>Активировать подписку и добавить проверок</>}
            </DialogTitle>
            <Form resource="service" onSubmit={handleDialogSubmit}>
                <>
                    <DialogContent>
                        <NumberInput source="addCnt" label="Добавить проверок" validate={required()} fullWidth/>
                    </DialogContent>
                    <DialogActions>
                        <Button label="Отмена" onClick={props.close}>
                            <IconCancel/>
                        </Button>
                        <SaveButton/>
                    </DialogActions>
                </>
            </Form>
        </Dialog>
    )
}


function ServicePanel() {
    const record = useRecordContext();
    return (
        <Box sx={{display:"flex"}}>
            <Typography variant={"body1"} sx={{padding:"1em"}}>Списания</Typography>
            <PartyInvoiceListSimple serviceId={record.id}/>
        </Box>
    )
}

const PartyServiceList = (props) => {
    const {permissions} = usePermissions();
    const {partyId, classes} = props;
    const [isAddCntDialogOpen, setAddCntDialogOpen] = useState(false);
    const [serviceId, setServiceId] = useState(null);
    const [serviceStateId, setServiceStateId] = useState(null);

    // console.log("perm", permissions, "roles", roles, permissions.includes(roles.melkocheck_admin));

    const openDialog = (serviceId, serviceStateId) => {
        setServiceId(serviceId);
        setServiceStateId(serviceStateId);
        setAddCntDialogOpen(true);
    }

    const rowstyle = (rec, index) => {
        return {
            backgroundColor: rec.serviceState.id == 100 ? '#efe' :
                (rec.serviceState.id == 900 ? 'LightGray' :
                    (rec.serviceState.id == 999 ? 'yellow' : 'white'))
        }
    }

    return (
        <React.Fragment>

            <Paper className={classes.container}>
                <IncreaseBalanceDialog
                    show={isAddCntDialogOpen}
                    close={() => setAddCntDialogOpen(false)}
                    getServiceId={() => serviceId}
                    getServiceStateid={()=> serviceStateId}
                />
                <List resource="service" filter={{"partyId": partyId}} actions={false} title=" "
                      disableSyncWithLocation
                >
                    <Datagrid bulkActionButtons={false} rowStyle={rowstyle}
                              expand={<ServicePanel/>}
                    >
                        <TextField source="id" label="ID" sortable={false}/>
                        <TextField source="serviceSpec.name" label="Тарифный план" sortable={false}/>
                        <FunctionField label="Цена" sx={{fontWeight: "bold"}} render={rec => {
                            return (rec.serviceSpec.price / 100).toLocaleString()
                        }}
                        />
                        <FunctionField label="Статус" render={rec => {
                            return rec.serviceState.text + " (" + rec.serviceState.id + ")"
                        }}/>
                        <FunctionField
                            label="Осталось проверок" render={rec => {
                            return <>
                                {rec.serviceSpec.serviceSpecType === 'Subscription' ?
                                    (rec.serviceUsageBalance / 100).toLocaleString('ru-RU') + ' руб.' :
                                    rec.serviceUsageBalance + ' шт.'
                                }
                                {rec.serviceSpec.serviceSpecType == "Bundle" &&
                                    (rec.serviceState.id == 100 || rec.serviceState.id == 900)
                                && permissions.includes(roles.melkocheck_admin)
                                    ?
                                    <Button onClick={() => openDialog(rec.id, rec.serviceState.id)}>
                                        {rec.serviceState.id == 100 && <AddCircle/>}
                                        {rec.serviceState.id == 900 && <Replay/>}
                                    </Button>

                                    : ""
                                }
                            </>
                        }}
                        />
                        <FunctionField label='Дата создания' render={rec => {
                            return formatDatetime(rec.createMoment)
                        }}/>
                        <FunctionField label="Период действия" render={rec => {
                            return formatDate(rec.startMoment) + ' --- ' +
                                (rec.stopMoment > Date.parse('2099-01-01') ? '' :
                                        formatDate(rec.stopMoment)
                                )
                        }}/>
                        <FunctionField label="Текущий расчетный период " render={rec => {
                            return formatDate(rec.currentPeriodStartMoment) + " --- " +
                                formatDate(rec.currentPeriodStopMoment)
                        }}/>
                    </Datagrid>
                </List>
            </Paper>
        </React.Fragment>
    );
}

export default withStyles(styles)(PartyServiceList);