import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Datagrid, ListContextProvider, TextField, useGetList } from 'react-admin';

const useDatagridStyles = makeStyles({
    headerCell: {
        backgroundColor: '#f0f0f0',
    },
    row: {
        backgroundColor: '#f0f0f0',
    },
});

const OperationAttributeList = (props) => {
    const operationId = props.id;
    const {data, total, isLoading, error} = useGetList('operationAttribute',
        {
            pagination: {page: 1, perPage: 100},
            sort: {field: 'id', order: 'ASC'},
            filter: {"operationId": operationId},
        },
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false
        });
    
    if (isLoading) {
        return null;
    }
    if (error) {
        return <p>ERROR: {error}</p>
    }
    /*if (total == 0) { return <p>Нет данных</p>     }*/

    return (

        <Paper style={{width: "100%", background: "#f0f0f0"}}>
            {!data || total == 0 ?
                <div style={{padding: "10px"}}>Нет данных</div>
                :
                <>
                    <Typography fontSize={20} ml={'10px'} mt={'10px'} mb={'5px'}>Аттрибуты</Typography>
                    <CustomAttributeList data={data} total={total}/>
                </>
            }
        </Paper>
    )

}

// data, total, page, perPage, setPage, filterValues, setFilters, sort
const CustomAttributeList = (props) => {
    const {data, total} = props;
    const listProps = {
        data,
        total,
        sort: {},
        resource: ""
    };

    const classes = useDatagridStyles();
    return (

        <ListContextProvider value={listProps}>
            <Datagrid classes={classes} isRowSelectable={() => {
                return false;
            }}
                      rowStyle={(record) => ({
                          backgroundColor: record &&
                          record.attribute.attribute &&
                          record.attribute.attribute.startsWith("response") ? 'lightGreen' : null
                      })}
            >
                {/*<TextField source="id" sortable={false}/>
                <TextField source="attribute.attribute" label="Аттрибут" sortable={false}/>*/}
                <TextField source="attribute.name" label="Название" sortable={false}/>
                <TextField source="value" label="Значение" sortable={false}/>
            </Datagrid>

        </ListContextProvider>
    )
}

export default OperationAttributeList;