import React from 'react';
import {
    Button,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    List,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    useListContext,
} from 'react-admin';

import { Paper, Box } from '@mui/material';
import moment from 'moment-timezone';
import BaseListActions from "../components/BaseListActions";
import OperationAttributeList from "./OperationAttributeList";
import OperationTypeLookup from "../components/OperationTypeLookup";

const styles = theme => ({
    container: { minWidth: '35em', marginLeft: '1em' },
    rightAlignedCell: { textAlign: 'right' },
    boldCell: { fontWeight: 'bold' },
    head: {
        backgroundColor: "#ededed",
        color: theme.palette.common.white,
    },
    row: {
        '&:nth-of-type(even)': {
            backgroundColor: theme.palette.background.default,
        },
    }
});

const OperFilter = (props) => {

    const { refetch } = useListContext();

    return (<>
                <Box style={{margin: '20px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end'}}>
                    <Filter {...props}>
                
                    <DateInput
                        alwaysOn
                        source="createMoment_gte" label="Дата с"
                        InputProps={{ style: { width: "144px", fontSize: "small" } }}
                    />
                    <DateInput
                        alwaysOn
                        source="createMoment_lte"
                        label="Дата по"
                        InputProps={{ style: { width: "144px", fontSize: "small" } }}
                    />

                    <OperationTypeLookup alwaysOn />

                    <ReferenceInput
                        alwaysOn
                        source="operationState.id"
                        reference="operationState"
                        perPage={1000}
                        sort={{ field: "name", order: "ASC" }}
                        translateChoice={false}
                        queryOptions={{
                            refetchOnWindowFocus: false,
                        }}
                    >
                        <SelectInput label="Результат" optionText="name" optionValue="id"
                            InputLabelProps={{ shrink: true }} sx={{ marginBottom: "0px" }}
                            InputProps={{ style: { fontSize: "small" } }}
                        />
                    </ReferenceInput>

                    <SelectInput
                        alwaysOn
                        label="Мелкочек"
                        source="relyingParty.billingEnabled"
                        choices={[
                            { id: 'true', name: 'Да' },
                            { id: 'false', name: 'Нет' },
                        ]}
                        sx={{ marginBottom: "0px" }}
                        translateChoice={false}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ style: { fontSize: "small" } }}

                    />
                    <TextInput
                        label="Контрагент" source="relyingParty.name_like" alwaysOn
                        InputLabelProps={{ shrink: true }}
                        InputProps={{ style: { fontSize: "small", minWidth: "140px" } }}
                    />
                </Filter>

                <Button
                    style={{width: '10%', height: '40px'}}
                    variant="contained"
                    onClick={refetch}
                    >
                    Загрузить данные
                </Button>
            </Box>
        </>
    )
};

class OperationList extends React.PureComponent {

    render() {
        const { ...other } = this.props;
        let d = new Date()
        const startDate = moment(d).format("YYYY-MM-DD");
        d.setDate(d.getDate() + 1);
        const stopDate = moment(d).format("YYYY-MM-DD");

        return (
            <Paper  style={{ marginTop: '20px',paddingBottom: '20px' }}>
                <List
                    {...other} title="Список операций" filters={<OperFilter />}
                    perPage={50}
                    queryOptions={{ enabled: false }}
                    sort={{ field: 'createMoment', order: 'DESC' }}
                    filterDefaultValues={{ createMoment_gte: startDate, createMoment_lte: stopDate }}
                    actions={/*<BaseListActions name="operation" exportFilename="operations.xlsx" />*/<></>}
                >
                    <Datagrid bulkActionButtons={false}
                        style={{width: '95%', margin: 'auto'}}
                        expand={<OperationAttributeList />}
                    >
                        <TextField source="id" />
                        <TextField source="operationNumber" label="Токен" />
                        <TextField source="operationType.name" label="Тип операции" />
                        <TextField source="operationState.name" label="Результат" />
                        <TextField source="relyingParty.name" label="Контрагент" />
                        <TextField source="serviceSupplier.name" label=" Поставщик" />
                        <DateField source="createMoment" label="Момент старта" showTime />
                        <DateField source="modifyMoment" label="Момент завершения" showTime />
                    </Datagrid>
                </List>
            </Paper>
        )
    }
}

export default OperationList;