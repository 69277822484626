import React from 'react';
import { Datagrid, ListContextProvider, TextField, DateField, useGetList, useRedirect, } from 'react-admin';
import { Paper, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useDatagridStyles = makeStyles({
    headerCell: {
        backgroundColor: '#f0f0f0',
    },
    row: {
        backgroundColor: '#f0f0f0',
    },
});

const OperationChildrenList = (props) => {
    const operationId = props.id;

    const { data, total, error, isLoading } = useGetList("findOperationToken", {
        filter: { "parentId": operationId },
    }, {
        enabled: !!operationId,
        refetchOnMount: false,
        refetchOnWindowFocus: false // Запрос выполняется только если есть operationId
    })

    if (isLoading) {
        return null;
    }

    if (error) {
        return <p>ERROR: {error}</p>
    }

    return <Paper style={{ width: "100%", background: "#f0f0f0" }}>
                <Typography fontSize={20} margin={'10px'}>Дочерние операции {!data || total == 0 
                    ? "не найдены" : ""}</Typography>
                    {data && total > 0 &&
                            <CustomChildrenList data={data} total={total} />
                    }
    </Paper>
}

export default OperationChildrenList;

const CustomChildrenList = (props) => {

    const { data, total } = props;
    const listProps = {
        data,
        total,
        sort: {},
        resource: "",
        queryOptions: {
            enabled: false
        },
    };

    const classes = useDatagridStyles();

    return (
        <ListContextProvider value={listProps}>
            <Datagrid classes={classes} isRowSelectable={() => {
                return false;
            }}
                rowClick={(id, str, record) => { window.open(`/operationToken/${record.operationNumber}`, "_blank");}}
            >
                <TextField source="id" />
                <TextField source="operationNumber" label="Токен" />
                <TextField source="operationType.name" label="Тип операции" />
                <TextField source="operationState.name" label="Результат" />
                <TextField source="relyingParty.name" label="Контрагент" />
                <TextField source="serviceSupplier.name" label=" Поставщик" />
                <DateField source="createMoment" label="Момент старта" showTime />
                <DateField source="modifyMoment" label="Момент завершения" showTime />
            </Datagrid>

        </ListContextProvider>
    )
}